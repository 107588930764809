// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {getAuth} from 'firebase/auth'
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYxGNqS9a8i8u_loYcJd5GWT5h4s0IdF4",
  authDomain: "al-razack-tours-internationals.firebaseapp.com",
  projectId: "al-razack-tours-internationals",
  storageBucket: "al-razack-tours-internationals.appspot.com",
  messagingSenderId: "874212021385",
  appId: "1:874212021385:web:9ec104a4f5a92f6b232258",
  measurementId: "G-ZXK987TEVH"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth=getAuth(app);
const storage = getStorage(app);
export { db ,auth,storage};