// import logo from './logo.svg';
import './App.css';
import {BrowserRouter,Routes,Route, Navigate} from 'react-router-dom'
import PackageView from './components/PackageView';
import Home from './components/Home';
import Admin from './components/Admin';
import AdminHandle from './pages/AdminHandle';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import HajjPackages from './pages/HajjPackages';
import UmrahPackages from './pages/UmrahPackages';
import RamadhanPackages from './pages/RamadhanPackages';
function App() {
  const currentUser=false;
  const RequireAuth=({children})=>{
    return currentUser?children:<Navigate to="/admin"/>
  }
  return (
    
    <BrowserRouter>
    
    <Routes>

      <Route path='/' element={<Home/>}/>
      <Route path='/about-us' element={<AboutUs/>}/>
      <Route path='/contact-us' element={<ContactUs/>}/>
      <Route path='/hajj-packages' element={<HajjPackages/>}/>
      <Route path='/umrah-packages' element={<UmrahPackages/>}/>
      <Route path='/ramadhan-packages' element={<RamadhanPackages/>}/>
      <Route path='/admin' element={<Admin/>}/>
      <Route path="/package-details/:id" Component={PackageView}/>
          
      <Route path='/admin-handle' element={
        // <RequireAuth>
        <AdminHandle/>
        // </RequireAuth>
        }/>
    </Routes>
    </BrowserRouter>

    );
}

export default App;
